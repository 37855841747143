import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Link from '../components/Link'
import Section from '../components/Section'
import Typography from '../components/Typography'
import Enrollment from '../components/Enrollment'
import Layout from '../layouts'
import Hero from '../components/EnrollmentHero'
import heroImage from '../images/enrollment-hero.jpg'

const Main = styled.main`
  display: block !important;
`

const Enroll = () => {
  let isIL = false
  if (typeof window !== 'undefined') {
    const parts = window.location.hash.split('/')
    isIL = parts.includes('IL')
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Enroll | Just Energy</title>
        <meta
          name="description"
          content="Just Energy is committed to providing you with comfort, convenience, and control through home energy solutions that meet your needs. Get competitive energy rates for your home today!"
        />
      </Helmet>
      <Layout>
        <Hero image={heroImage}>
          <Typography variant="h1">Enrollment</Typography>
        </Hero>
        {isIL && (
          <Section style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <p>
              To find the price to compare, click{' '}
              <Link to="/locations/illinois">here.</Link>
            </p>
          </Section>
        )}
        <Main>
          <Enrollment basePath="/enroll" />
        </Main>
      </Layout>
    </>
  )
}

export default Enroll
